// Configure fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowsAlt,
    faBars,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faDownload,
    faFileUpload,
    faHome,
    faImages,
    faLock,
    faSignOutAlt,
    faTag,
    faTimes,
    faTrashAlt,
    faUser,
    faUsers,
    faUserShield
} from '@fortawesome/free-solid-svg-icons'
import {
    faCheckSquare,
    faSquare
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowsAlt,
    faBars,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faCheckSquare,
    faDownload,
    faFileUpload,
    faHome,
    faImages,
    faLock,
    faSignOutAlt,
    faSquare,
    faTag,
    faTimes,
    faTrashAlt,
    faUser,
    faUsers,
    faUserShield
)
