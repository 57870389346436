<template>
    <header class="navbar">
        <div class="nav-section">
            <button class="navbar-link expand" @click="$store.commit('toggleSidebar')" v-if="$auth.isAdmin && $route.path.startsWith('/admin/')" title="Expand">
                <FontAwesomeIcon icon="bars"/>
            </button>
            <router-link class="navbar-link" :to="{name: 'Welcome'}" :class="{active: $route.name == 'Welcome'}" title="Home">
                <FontAwesomeIcon icon="home"/>
            </router-link>
            <router-link class="navbar-link" :to="{name: 'Photos'}" :class="{active: $route.name == 'Photos'}" title="Photos">
                <FontAwesomeIcon icon="images"/>
            </router-link>
            <router-link v-if="$auth.isAdmin" class="navbar-link" :to="{name: 'Upload'}" :class="{active: $route.meta.requiresAdmin}" title="Admin">
                <FontAwesomeIcon icon="user-shield"/>
            </router-link>
        </div>
        <template v-if="$route.name == 'Photos' || $route.name == 'ViewPhoto'" class="nav-section">
            <LabelScroll v-if="largeScreen" class="nav-section labels"></LabelScroll>
            <LabelDropdown v-else class="nav-section labels"></LabelDropdown>
        </template>
        <div class="nav-section">
            <button class="navbar-link" @click="logout" v-if="$auth.isAuthenticated" title="Logout">
                <FontAwesomeIcon icon="sign-out-alt"/>
            </button>
        </div>
    </header>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueScreenSize from 'vue-screen-size'

export default {
    name: 'NavbarTop',
    components: {
        FontAwesomeIcon,
        LabelScroll: () => import('@/components/LabelScroll'),
        LabelDropdown: () => import('@/components/LabelDropdown')
    },
    computed: {
        largeScreen() {
            return this.$vssWidth > 512
        }
    },
    methods: {
        logout() {
            const next = this.$auth.isAdmin ? 'AdminLogin' : 'Login'
            this.$auth.logout('logout/')
            .then(() => {
                this.$router.push({name: next})
            })
        }
    },
    mixins: [
        VueScreenSize.VueScreenSizeMixin
    ],
}
</script>

<style scoped>
.navbar {
    z-index: 4;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #cfffe5;
    border-bottom: 1px solid #98ff98;
    padding: .75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    user-select: none;
}
.nav-section {
    display: flex;
}
.labels {
    flex: 1 1;
    padding: 0 1rem;
    overflow-x: hidden;
}
::v-deep .navbar-link {
    margin-right: 1rem;
    padding: .25rem .5rem;
    color: black;
    text-decoration: none;
    font-size: 1rem;
    color: green;
    border-radius: .5rem;
    user-select: none;
}
::v-deep a.navbar-link {
    display: inline-block;
}
::v-deep button.navbar-link {
    background: none;
    border: none;
    cursor: pointer;
}
::v-deep .navbar-link.active {
    background-color: #8fffc3;
}
@media (min-width: 769px) {
    .expand {
        display: none;
    }
}
</style>