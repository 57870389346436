import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
Vue.use(VueAxios, axios)

export default {
    install(Vue) {
        Vue.prototype.$auth = {
            isAuthenticated: window.localStorage.getItem('auth') == 'true',
            isAdmin: window.localStorage.getItem('admin') == 'true',
            
            login(url, password) {
                const credentials = `password=${password}`
                return Vue.axios.post(url, credentials, {headers: {'content-type': 'application/x-www-form-urlencoded'}}).then(() => {
                    this.isAuthenticated = true
                    this.isAdmin = false
                    window.localStorage.setItem('auth', 'true')
                    window.localStorage.setItem('admin', 'false')
                })
            },

            loginAdmin(url, username, password) {
                const credentials = `username=${username}&password=${password}`
                return Vue.axios.post(url, credentials, {headers: {'content-type': 'application/x-www-form-urlencoded'}}).then(() => {
                    this.isAuthenticated = true
                    this.isAdmin = true
                    window.localStorage.setItem('auth', 'true')
                    window.localStorage.setItem('admin', 'true')
                })
            },

            logout(url) {
                return Vue.axios.post(url).then(() => {
                    this.isAuthenticated = false
                    this.isAdmin = false
                    window.localStorage.removeItem('auth')
                    window.localStorage.removeItem('admin')
                })
            }
        }
    }
}

axios.interceptors.response.use(null, err => {
    if (err.response.status == 401) {
        Vue.prototype.$auth.isAuthenticated = false
        Vue.prototype.$auth.isAdmin = false
        window.localStorage.removeItem('auth')
        window.localStorage.removeItem('admin')
    }
    else if (err.response.status == 403) {
        Vue.prototype.$auth.isAdmin = false
        window.localStorage.removeItem('admin')
    }
    return Promise.reject(err)
})
