import Vue from 'vue'
import App from './App.vue'
import Auth from './config/auth'
import store from './config/store'
import router from './config/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {VueMasonryPlugin} from 'vue-masonry'

import '@/config/fontawesome'

// Configure axios
axios.defaults.withCredentials = true
axios.defaults.baseURL = '/api'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

// Configure other plugins
Vue.use(Auth)
Vue.use(VueMasonryPlugin)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
