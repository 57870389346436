import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sidebar: {
            expanded: false
        },
        keyBindings: [],
        activeLabel: null,
        filterLabel: null,
        groups: [],
        labels: []
    },
    mutations: {
        bindKeys(state, func) {
            window.addEventListener('keyup', func)
            state.keyBindings.push(func)
        },
        unbindKeys(state) {
            state.keyBindings.forEach(func => {
                window.removeEventListener('keyup', func)
            })
            state.keyBindings = []
        },
        toggleSidebar(state) {
            state.sidebar.expanded = !state.sidebar.expanded
            //state.sidebar.expanded ? document.documentElement.classList.add('overlay') : document.documentElement.classList.remove('overlay')
        },
        closeSidebar(state) {
            state.sidebar.expanded = false
            //document.documentElement.classList.remove('overlay')
        },
        selectLabel(state, label) {
            state.activeLabel = label
        },
        filterLabel(state, label) {
            state.filterLabel = state.filterLabel && label && state.filterLabel.id == label.id ? null : label
        },
        setGroups(state, groups) {
            state.groups = groups
        },
        addGroup(state, group) {
            state.groups.push(group)
            state.groups.sort((group1, group2) => (group1.name > group2.name) ? 1 : -1)
        },
        removeGroup(state, group) {
            state.groups.splice(state.groups.indexOf(group), 1)
        },
        setLabels(state, labels) {
            state.labels = labels
        },
        addLabel(state, label) {
            state.labels.push(label)
            state.labels.sort((label1, label2) => (label1.order > label2.order) ? 1 : -1)
        },
        removeLabel(state, label) {
            state.labels.splice(state.labels.indexOf(label), 1)
        }
    },
    actions: {
    },
    modules: {
    }
})
