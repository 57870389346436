import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../config/auth'
import Welcome from '@/views/Welcome'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueRouter)
Vue.use(Auth)
Vue.use(VueAxios, axios)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login')
    },
    {
        path: '/admin/login',
        name: 'AdminLogin',
        component: () => import('../views/Login')
    },
    {
        path: '',
        name: 'Welcome',
        component: Welcome,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/photos',
        name: 'Photos',
        component: () => import('../views/Photos'),
        children: [
            {
                path: '/view/:id+',
                name: 'ViewPhoto',
                component: () => import('../views/ViewPhoto'),
                meta: {
                    requiresAuth: true
                }
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin',
        component: () => import('../views/Admin'),
        children: [
            {
                path: '',
                name: 'Upload',
                component: () => import('../views/Upload'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true
                }
            },
            {
                path: 'groups/:id+',
                name: 'Group',
                component: () => import('../views/Group'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true
                }
            },
            {
                path: 'logs',
                name: 'Logs',
                component: () => import('../views/Logs'),
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true
                }
            }
        ],
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    // Require admin login for admin pages
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!router.app.$auth.isAdmin) {
            next({name: 'AdminLogin', query: {next: to.fullPath}})
        }
    }

    // General pages require default login 
    else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!router.app.$auth.isAuthenticated) {
            next({name: 'Login', query: {next: to.fullPath}})
        }
    }

    // The login pages redirect if the user is already authenticated
    else if (to.name == 'AdminLogin' && router.app.$auth.isAdmin) {
        next({name: 'Welcome'})
    }
    else if (to.name == 'Login' && router.app.$auth.isAuthenticated && !router.app.$auth.isAdmin) {
        next({name: 'Welcome'})
    }

    // If all tests have been passed, proceed to next page
    next()
})

axios.interceptors.response.use(null, err => {
    const current = window.location.pathname
    if (err.response.status == 403 || (err.response.status == 401 && current.startsWith('/admin'))) {
        router.push({name: 'AdminLogin', query: {next: current}}, null, () => {})
    }
    else if (err.response.status == 401) {
        router.push({name: 'Login', query: {next: current}}, null, () => {})
    }
    return Promise.reject(err)
})

export default router
