<template>
    <div id="app">
        <NavbarTop></NavbarTop>
        <main>
            <router-view></router-view>
        </main>
    </div>
</template>

<script>
import NavbarTop from '@/components/NavbarTop'

export default {
    components: {
        NavbarTop
    }
}
</script>

<style>
*, *:before, *:after {
    box-sizing: border-box;
}
html,
body {
    margin: 0;
}
body {
    background-color: white;
    padding-top: 3.5rem;
    overflow-x: hidden;
}
body.overlay {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
body,
input,
textarea,
button {
    font-family: Didot, 'Didot LT STD', 'Hoefler Text', Garamond, 'Times New Roman', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
