<template>
    <div class="welcome">
        <div class="header">
            <img src="@/assets/header.png">
        </div>
        <hr>
        <div class="message">
            <img src="@/assets/welcome.png">
        </div>
        <router-link :to="{name: 'Photos'}" class="link">VIEW PHOTOS</router-link>
    </div>
</template>

<script>
export default {
    name: 'Welcome'
}
</script>

<style scoped>
.welcome {
    padding: 1rem;
    text-align: center;
}
.message {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
}
.link {
    display: block;
    font-size: .75rem;
    color: rgb(97, 29, 29);
    text-decoration: none;
    margin: 2rem;
}
img {
    max-width: 100%;
    max-height: 100%;
}
hr {
    margin: 2rem 2rem;
    overflow: visible;
    padding: 0;
    border: none;
    border-top: medium double green;
    color: green;
    text-align: center;
}
hr:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: white;
}
</style>